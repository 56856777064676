import {useId} from 'react';
import cn from 'classnames';

import {parseMarkdown} from '@/utils/markdown';
import Col, {Span, Start} from '@/components/brochureV2//layout/Grid/Col';
import Section from '@/components/brochureV2//primitive/Section/Section';
import Minus from '@/components/brochureV2/icons/Minus';
import Plus from '@/components/brochureV2/icons/Plus';
import Grid from '@/components/brochureV2/layout/Grid/Grid';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Accordion from '@/components/shared/Accordion/Accordion';
import {DivHtml} from '@/components/shared/HtmlElements';
import {ColorSchemeEnum} from '@/enums';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';
import {twMerge} from '@/stylesheets/twMerge';

export interface FaqComponent {
  answer?: string;
  question?: string;
}

export interface FaqTopicsComponent {
  heading?: string;
  questions?: FaqComponent[];
}

export interface FAQProps {
  colorScheme?: ColorSchemeEnum;
  heading?: string;
  questions?: FaqComponent[];
  faqs?: FaqTopicsComponent[];
  sectionName?: string;
  className?: string;
  plusIconStyles?: string;
  minusIconStyles?: string;
  borderShade?: string;
  startOpen?: boolean;
}

const AccordionContainer = ({children}: any) => (
  <Grid>
    <Col
      start={{default: Start.One, sm: Start.Two, md: Start.Three}}
      span={{default: Span.Nine, sm: Span.Six, md: Span.Nine}}
    >
      {children}
    </Col>
  </Grid>
);

/**
 * @deprecated
 */
export default function FAQ({
  heading,
  questions,
  faqs,
  className,
  colorScheme,
  borderShade = 'border-shade-20',
  plusIconStyles = ColorSchemeEnum.Dark
    ? 'bg-white text-black group-hover:bg-shade-30 group-active:bg-shade-50'
    : 'bg-shade-100 text-white group-hover:bg-shade-70 group-active:bg-shade-50',
  minusIconStyles = ColorSchemeEnum.Dark
    ? 'bg-black text-white border-2 border-white group-hover:border-shade-30 group-active:border-shade-50'
    : 'bg-white text-black border-2 border-shade-100 group-hover:border-shade-70 group-active:border-shade-50',
  startOpen = false,
  ...props
}: FAQProps) {
  if ((!questions || questions.length === 0) && (!faqs || faqs.length === 0)) {
    return null;
  }
  const currentScheme = colorScheme || ColorSchemeEnum.Light;

  const listAttributes = {
    itemScope: true,
    itemType: 'https://schema.org/FAQPage',
  };

  const listItemAttributes = {
    itemScope: true,
    itemProp: 'mainEntity',
    itemType: 'https://schema.org/Question',
  };

  const toggleWrapperStyles = [
    ColorSchemeEnum.Dark,
    ColorSchemeEnum.PosDark,
  ].includes(currentScheme)
    ? 'text-white hover:text-shade-30 active:text-shade-50'
    : 'text-black hover:text-shade-70 active:text-shade-50';

  const textSize = faqs
    ? {
        question: 'text-xl leading-7',
        answer: 'text-[1.125rem] leading-[1.625rem]',
      }
    : {
        question: 'text-2xl leading-[1.625rem] md:text-[2rem] md:leading-9',
        answer: 'text-xl leading-7 md:text-[1.125rem] md:leading-[1.625rem]',
      };
  const toggleIconStyles = `rounded-full p-1.5 w-[44px] h-[44px]`;
  const focusStyles =
    'group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-state-focus';
  const toggleIcons = {
    expand: (
      <Plus
        className={twMerge(toggleIconStyles, plusIconStyles, focusStyles)}
      />
    ),
    collapse: (
      <Minus
        className={twMerge(toggleIconStyles, minusIconStyles, focusStyles)}
      />
    ),
  };

  const AccordionMarkup = ({
    questions: items,
    isNested,
  }: {
    questions: FaqComponent[];
    isNested: boolean;
    startOpen: boolean;
  }) => {
    const accordionItems = items.map((faqItem) => {
      return {
        buttonText: (
          <span
            className={`text-left font-bold ${textSize.question}`}
            itemProp="name"
          >
            {faqItem.question}
          </span>
        ),
        body: faqItem.answer ? (
          <div
            itemProp="acceptedAnswer"
            itemScope
            itemType="https://schema.org/Answer"
          >
            <DivHtml
              className={`richtext mt-sm mr-16 md:w-4/5 ${textSize.answer}`}
              itemProp="text"
            >
              {parseMarkdown(faqItem.answer)}
            </DivHtml>
          </div>
        ) : null,
      };
    });

    return (
      <Accordion
        accordionItems={accordionItems}
        className=""
        id={'faq-' + useId()}
        listItemAttributes={listItemAttributes}
        listStyles={cn('mb-0 pt-8 pb-8 md:p-10 border-b-2', borderShade, {
          'md:last:border-0': !isNested,
        })}
        toggleWrapperStyles={`group after:hidden ${toggleWrapperStyles}`}
        startOpen={startOpen}
        toggleIcons={toggleIcons}
        toggleWrapperClassName="relative pr-16"
        toggleClassName="absolute right-0 top-0"
      />
    );
  };

  return (
    <Section
      sectionName="faq"
      {...props}
      className={cn(className, sectionSchemeClasses[currentScheme])}
    >
      {heading && (
        <Grid>
          <Col start={{default: Start.One}} span={{default: Span.Eight}}>
            <Spacer size="3xl" />
            <h2 className="font-bold text-t2">{heading}</h2>
            <Spacer size="2xl" />
          </Col>
        </Grid>
      )}
      <div {...listAttributes}>
        {questions && (
          <AccordionContainer>
            <AccordionMarkup
              questions={questions}
              isNested={false}
              startOpen={startOpen}
            />
          </AccordionContainer>
        )}
        {faqs && (
          <AccordionContainer>
            {faqs?.map((topic: any, index: number) => {
              return (
                <Section key={topic.heading} {...props}>
                  <h3
                    className={cn('font-bold mt-2xl mb-xl text-t4', {
                      'mt-0': index === 0,
                    })}
                  >
                    {topic.heading}
                  </h3>
                  <AccordionMarkup
                    questions={topic.questions}
                    isNested
                    startOpen={startOpen}
                  />
                </Section>
              );
            })}
          </AccordionContainer>
        )}
      </div>
      <Spacer size="3xl" />
    </Section>
  );
}
